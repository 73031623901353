<footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4-1">
          <h3 style="font-size: 30px;">Estamos aquí para ayudarte</h3>
          <p>Resolveremos todas tus inquietudes y con tus preguntas<br> te llevaremos al lugar indicado.</p>
          <div class="btn-group">
            <button class="btn btn-primary" (click)="goToSales()">Habla con un asesor</button>
            <button class="btn btn-secondary" (click)="goToSupport()">Soporte técnico</button>
          </div>
        </div>
        <div class="col-md-4">
            <h3>Candidatos</h3>
            <ul class="list-unstyled">
              <li><a href="/registro?registro=candidato">Registrarse</a></li>
              <li><a href="/lista-vacantes?palabra=">Buscar vacantes</a></li>
              <li><a href="https://okempleos.com/calculadora-salarial/">Calculadora salarial</a></li>
              <li><a href="https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ?">Escuela de la empleabilidad</a></li>
              <li><a href="https://okempleos.com/eventos/">Eventos laborales</a></li>
              <li><a href="https://www.youtube.com/watch?v=RJLpMD28QNA&list=PLUP-zPewRTowjcsxy6Id_MwEgWWWcaew-&ab_channel=OkEmpleos">Tutoriales</a></li>
            </ul>
        </div>
        <div class="col-md-4">
            <h3>Empresas</h3>
            <ul class="list-unstyled">
              <li><a href="registro?registro=empresa">Registrarse</a></li>
              <li><a href="/planes">Planes</a></li>
              <li><a href="https://gesth.co">Outsourcing de selección</a></li>
              <li><a href="https://api.whatsapp.com/send?phone=573134433778&text=Hola%20OK%20EMPLEOS%2C%20%2Asoy%20una%20empresa%2A%20interesada%20en%20sus%20servicios.%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20%5BColoque%20aqu%C3%AD%20el%20servicio%20sobre%20el%20que%20desea%20obtener%20informaci%C3%B3n%5D" target="_blank">Contacto empresarial</a></li>
            </ul>
        </div>
        <div class="col-md-4">
          <h3>Enlace de interés</h3>
          <ul class="list-unstyled">
            <li><a href="https://okempleos.com/blog/">Blog</a></li>
            <li><a href="/politicas">Políticas de privacidad</a></li>
            <li><a href="/terminos">Términos y condiciones</a></li>
            <li><a href="https://gesth.co/outsourcing-de-reclutamiento-y-seleccion.html">Servicios de selección</a></li>
          </ul>
        </div>
      </div>
      <div class="row" style="justify-content: right;">
        <div class="social">
          <img src="/assets/images/logos/ok_logo_horizontal.svg" alt="Logo">
          <a href="https://www.tiktok.com/@en.modo.ok?lang=es" target="_blank">
            <img src="/assets/images/home/tiktok.svg" alt="Tiktok">
        </a>
        <a href="https://www.instagram.com/Ok.empleos/" target="_blank">
          <img src="/assets/images/home/instagram.svg" alt="Instagram">
        </a>
        <a href="https://www.linkedin.com/company/ok-empleos/" target="_blank">
            <img src="/assets/images/home/linkedin.svg" alt="Linkedin">
        </a>
        <a href="https://www.facebook.com/OkEmpleosColombia" target="_blank">
          <img src="/assets/images/home/facebook.svg" alt="Facebook">
        </a>
        <a href="https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ" target="_blank">
          <img src="/assets/images/home/youtube.svg" alt="Youtube">
        </a>
        <a href="https://twitter.com/Ok_Empleos" target="_blank">
          <img src="/assets/images/home/twitter.svg" alt="Twitter">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.okempleos.app.twa&hl=es_CO" target="_blank">
          <img src="/assets/images/home/play_store.png" style="height: 75px; margin-bottom: 15%;" alt="Play Store">
        </a>
        </div>
      </div>
    </div>
<!--     <div class="row copyright" style="align-items: center;">
      <div class="col-md-4" style="flex: 1 0 13%;">
      </div>
      <div class="col-md-4">
        <img src="/assets/images/home/SPE.png" alt="Servicio Público de Empleo">
        <img src="/assets/images/home/MinTrabajo.png" style="width: 50%; margin: 10px;" alt="Ministerio del Trabajo">
      </div>
      <div class="col-md-6">
        <p class="text-center copyright" style="color: #FFFFFF; font-size:10px;" ><a href="https://okempleos.com/wp-content/uploads/2022/05/RESOLUCiON-0094-2.pdf" style="color: #FAFAFA; padding-left: 0;">Estamos vinculados a la red de prestadores del Servicio Público de empleo y autorizados por la Unidad Administrativa Especial del Servicio Público de Empleo. &copy; 2024 okempleos.com. Todos los derechos reservados.</a></p>
      </div>
      <div class="col-md-4">
        <img src="/assets/images/home/SelloOro.png" style="width: 27%;" alt="Sello Oro Bogotá Incluyente">
      </div>
      <div class="col-md-4" style="flex: 1 0 13%;">
      </div>
    </div> -->
  </footer>
  